import { Currency, Network, Token } from '@hyperjump-defi/sdk'
import React, { useMemo } from 'react'
import styled from 'styled-components'
import getNetwork from 'utils/getNetwork'

import { useAllTokens } from 'hooks/Tokens'
import { filterTokens } from '../../views/Farms/components/filtering'

import useHttpLocations from '../../hooks/useHttpLocations'
import { WrappedTokenInfo } from '../../state/lists/hooks'
import Logo from '../Logo'

// FIXME replace this
const getTokenLogoURL = (address: string, chainId: number) =>
  `https://github.com/HyperJump-DeFi/evm-token-icons/blob/main/${chainId}/${address.toLowerCase()}.png?raw=true`

const StyledLogo = styled(Logo)<{ size: string }>`
  width: ${({ size }) => size};
  height: ${({ size }) => size};
`

export default function CurrencyLogo({
  currency,
  size = '24px',
  style,
}: {
  currency?: Currency
  size?: string
  style?: React.CSSProperties
  lpUrl?: string
}) {
  const uriLocations = useHttpLocations(currency instanceof WrappedTokenInfo ? currency.logoURI : undefined)
  const { config, chainId } = getNetwork()
  const allTokens = useAllTokens()
  const searchTokenIconName = filterTokens(Object.values(allTokens), config.networkToken?.symbol.toLowerCase())

  const defaultTokenIcon =
    config.networkToken?.symbol.toLowerCase() === 'bnb'
      ? '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c'.toLowerCase()
      : config.networkToken?.symbol.toLowerCase() === 'ftm'
      ? '0x21be370D5312f44cB42ce377BC9b8a0cEF1A4C83'.toLowerCase()
      : config.networkToken?.symbol.toLowerCase() === 'metis'
      ? '0xDeadDeAddeAddEAddeadDEaDDEAdDeaDDeAD0000'.toLowerCase()
      : 'token'

  const netWorkTokenIconImg =
    searchTokenIconName[0]?.address === undefined ? defaultTokenIcon : searchTokenIconName[0]?.address

  const srcs: string[] = useMemo(() => {
    if (currency === config.baseCurrency) {
      return [
        `https://github.com/HyperJump-DeFi/evm-token-icons/blob/main/${chainId}/${netWorkTokenIconImg.toLowerCase()}.png?raw=true`,
        getTokenLogoURL(netWorkTokenIconImg, chainId),
      ]
    }
    if (currency instanceof Token) {
      if (currency instanceof WrappedTokenInfo) {
        return [
          ...uriLocations,
          `https://github.com/HyperJump-DeFi/evm-token-icons/blob/main/${chainId}/${
            currency?.address.toLowerCase() ?? 'token'
          }.png?raw=true`,
          getTokenLogoURL(currency.address.toLowerCase(), chainId),
        ]
      }
      return [
        `https://github.com/HyperJump-DeFi/evm-token-icons/blob/main/${chainId}/${
          currency?.address.toLowerCase() ?? 'token'
        }.png?raw=true`,
        getTokenLogoURL(currency.address.toLowerCase(), chainId),
      ]
    }
    return []
  }, [config.baseCurrency, currency, uriLocations, netWorkTokenIconImg, chainId])

  return <StyledLogo size={size} srcs={srcs} alt={`${currency?.symbol ?? 'token'} logo`} style={style} />
}
