import React from 'react'
import Svg from '../../../components/Svg/Svg'
import { SvgProps } from '../../../components/Svg/types'

interface LogoProps extends SvgProps {
  isDark: boolean
}

const Logo: React.FC<LogoProps> = (props) => {
  return (
    <Svg viewBox="0 0 20 20" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.4773 0.0833282H1.52271C0.727752 0.0833282 0.083313 0.792211 0.083313 1.66666V14.3333C0.083313 15.2078 0.727752 15.9167 1.52271 15.9167H2.45831V16.7083H4.04165V15.9167H11.9583V16.7083H13.5416V15.9167H14.4773C15.2722 15.9167 15.9166 15.2078 15.9166 14.3333V1.66666C15.9166 0.792211 15.2722 0.0833282 14.4773 0.0833282ZM1.66665 14.3333V1.66666H14.3333V3.24999H13.5416V4.83333H14.3333V11.1667H13.5416V12.75H14.3333V14.3333H1.66665ZM12.5181 11.3985L11.3985 12.5181L9.60862 10.7282C9.13717 11.0068 8.58724 11.1667 7.99998 11.1667C7.41271 11.1667 6.86279 11.0068 6.39134 10.7282L4.60144 12.5181L3.48185 11.3985L5.27175 9.60863C4.99317 9.13719 4.83331 8.58726 4.83331 8C4.83331 7.41273 4.99317 6.8628 5.27175 6.39136L3.48185 4.60145L4.60144 3.48187L6.39134 5.27177C6.86279 4.99319 7.41271 4.83333 7.99998 4.83333C8.58724 4.83333 9.13717 4.99319 9.60862 5.27177L11.3985 3.48187L12.5181 4.60145L10.7282 6.39135C11.0068 6.8628 11.1666 7.41273 11.1666 8C11.1666 8.58726 11.0068 9.13719 10.7282 9.60864L12.5181 11.3985ZM9.58331 7.99999C9.58331 8.87444 8.87443 9.58333 7.99998 9.58333C7.12553 9.58333 6.41665 8.87444 6.41665 7.99999C6.41665 7.12554 7.12553 6.41666 7.99998 6.41666C8.87443 6.41666 9.58331 7.12554 9.58331 7.99999Z"
        fill="#44C4E2"
      />
    </Svg>
  )
}

export default Logo
