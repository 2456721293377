import React from 'react'
import Svg from '../../../components/Svg/Svg'
import { SvgProps } from '../../../components/Svg/types'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 20 20" {...props}>
      <path
        d="M13.0553 10.7221C12.9592 10.7221 12.8816 10.6445 12.8816 10.5484V7.96748C12.8816 7.87137 12.9592 7.79379 13.0553 7.79379H14.4285C14.5246 7.79379 14.6022 7.87137 14.6022 7.96748V10.5484C14.6022 10.6445 14.5246 10.7221 14.4285 10.7221H13.0553Z"
        fill="#44C4E2"
      />
      <path
        d="M14.4285 7.96747V10.5484H13.0564V7.96747H14.4285ZM14.4285 7.6201H13.0564C12.8642 7.6201 12.709 7.77526 12.709 7.96747V10.5484C12.709 10.7406 12.8642 10.8958 13.0564 10.8958H14.4285C14.6207 10.8958 14.7759 10.7406 14.7759 10.5484V7.96747C14.7759 7.77642 14.6207 7.6201 14.4285 7.6201Z"
        fill="#44C4E2"
      />
      <path
        d="M10.3169 13.7847C10.2208 13.7847 10.1432 13.7072 10.1432 13.6111V6.75401C10.1432 6.6579 10.2208 6.58032 10.3169 6.58032H11.689C11.7851 6.58032 11.8627 6.6579 11.8627 6.75401V13.6111C11.8627 13.7072 11.7851 13.7847 11.689 13.7847H10.3169Z"
        fill="#44C4E2"
      />
      <path
        d="M11.689 6.754V13.6111H10.3169V6.754H11.689ZM11.689 6.40663H10.3169C10.1246 6.40663 9.96948 6.56179 9.96948 6.754V13.6111C9.96948 13.8033 10.1246 13.9584 10.3169 13.9584H11.689C11.8812 13.9584 12.0363 13.8033 12.0363 13.6111V6.754C12.0363 6.56179 11.8812 6.40663 11.689 6.40663Z"
        fill="#44C4E2"
      />
      <path
        d="M7.57145 14.7377C7.47535 14.7377 7.39777 14.6601 7.39777 14.564V11.5546C7.39777 11.4585 7.47535 11.381 7.57145 11.381H9.04313C9.13924 11.381 9.21682 11.4585 9.21682 11.5546V14.5628C9.21682 14.6589 9.13924 14.7365 9.04313 14.7365H7.57145V14.7377Z"
        fill="#44C4E2"
      />
      <path
        d="M9.04195 11.5546V14.5617H7.57143V11.5546H9.04195ZM9.04195 11.2073H7.57143C7.37922 11.2073 7.22406 11.3624 7.22406 11.5546V14.5617C7.22406 14.7539 7.37922 14.9091 7.57143 14.9091H9.04195C9.23417 14.9091 9.38932 14.7539 9.38932 14.5617V11.5546C9.38932 11.3636 9.23417 11.2073 9.04195 11.2073Z"
        fill="#44C4E2"
      />
      <path
        d="M3.43773 10.105C3.39836 10.105 3.36015 10.0922 3.32773 10.0667C3.28026 10.0274 3.2571 9.96716 3.2652 9.90695C3.7411 6.49811 6.42973 3.78169 9.82236 3.26759L10.8471 0.440008C10.8726 0.369377 10.9386 0.324219 11.0104 0.324219C11.0196 0.324219 11.03 0.324219 11.0405 0.326535C11.1238 0.341587 11.184 0.413377 11.184 0.497903V4.07927C11.184 4.17538 11.1065 4.25296 11.0104 4.25296C7.80068 4.25296 5.01825 6.53517 4.39531 9.68001C4.38373 9.74022 4.34204 9.78885 4.28415 9.80969L3.49678 10.0945C3.4771 10.1015 3.45741 10.105 3.43773 10.105Z"
        fill="#44C4E2"
      />
      <path
        d="M11.0104 0.497896V4.07926C7.65829 4.07926 4.85271 6.47379 4.22397 9.64642L3.4366 9.93126C3.90671 6.56295 6.57565 3.88937 9.94976 3.4239L11.0104 0.497896ZM11.0104 0.150528C10.8668 0.150528 10.7348 0.239686 10.6839 0.379791L9.69386 3.11126C6.2746 3.67516 3.57555 6.43211 3.09386 9.88263C3.0765 10.0031 3.12397 10.1246 3.21892 10.2011C3.28144 10.252 3.35902 10.2786 3.43776 10.2786C3.47713 10.2786 3.51765 10.2717 3.55586 10.2578L4.34323 9.97295C4.45786 9.93242 4.54239 9.834 4.56555 9.71474C5.17344 6.65095 7.88407 4.42779 11.0104 4.42779C11.2026 4.42779 11.3578 4.27263 11.3578 4.08042V0.497896C11.3578 0.328844 11.2362 0.185265 11.0706 0.156317C11.0509 0.152844 11.0301 0.150528 11.0104 0.150528Z"
        fill="#44C4E2"
      />
      <path
        d="M17.9126 11.176C17.8165 11.176 17.7389 11.0984 17.7389 11.0023C17.7389 7.79264 15.4567 5.01022 12.3118 4.38611C12.2516 4.37453 12.203 4.33285 12.1833 4.27496L11.8985 3.48759C11.8765 3.42969 11.888 3.36601 11.9262 3.31853C11.9587 3.27685 12.0085 3.25369 12.0606 3.25369C12.0687 3.25369 12.0768 3.25369 12.0849 3.25485C15.4949 3.73075 18.2113 6.41938 18.7254 9.81201L21.5518 10.8367C21.6306 10.8657 21.678 10.9467 21.6641 11.0301C21.6502 11.1135 21.5785 11.1737 21.4939 11.1737H17.9126V11.176Z"
        fill="#44C4E2"
      />
      <path
        d="M12.0606 3.42969C15.4289 3.89979 18.1025 6.56874 18.568 9.94285L21.494 11.0035H17.9126C17.9126 7.65137 15.5181 4.84579 12.3454 4.21706L12.0606 3.42969ZM12.0606 3.08232C11.9564 3.08232 11.8568 3.12864 11.7908 3.21085C11.7144 3.30579 11.6924 3.43316 11.7341 3.54779L12.0189 4.33516C12.0606 4.44979 12.159 4.53432 12.2783 4.55748C15.3421 5.16537 17.5652 7.87601 17.5652 11.0023C17.5652 11.1945 17.7204 11.3497 17.9126 11.3497H21.494C21.663 11.3497 21.8066 11.2281 21.8355 11.0625C21.8645 10.897 21.7707 10.7337 21.6121 10.6758L18.8806 9.68579C18.3167 6.26653 15.5597 3.56748 12.1092 3.08579C12.093 3.08348 12.0768 3.08232 12.0606 3.08232Z"
        fill="#44C4E2"
      />
      <path
        d="M10.9641 21.6723C10.8807 21.6573 10.8205 21.5866 10.8205 21.5021V17.9208C10.8205 17.8246 10.8981 17.7471 10.9942 17.7471C14.2039 17.7471 16.9863 15.4649 17.6092 12.32C17.622 12.2598 17.6637 12.2112 17.7215 12.1915L18.5101 11.9066C18.5298 11.8985 18.5483 11.8951 18.5691 11.8951C18.6085 11.8951 18.6467 11.909 18.6791 11.9344C18.7266 11.9726 18.7509 12.0329 18.7417 12.0931C18.2658 15.5031 15.5771 18.2195 12.1845 18.7336L11.1574 21.56C11.132 21.6306 11.066 21.6758 10.9942 21.6758C10.9849 21.6758 10.9745 21.6746 10.9641 21.6723Z"
        fill="#44C4E2"
      />
      <path
        d="M18.5679 12.0687C18.0978 15.4371 15.4289 18.1106 12.0548 18.5761L10.9942 21.5021V17.9207C14.3463 17.9207 17.1518 15.5262 17.7806 12.3536L18.5679 12.0687ZM18.5679 11.7214C18.5286 11.7214 18.4881 11.7283 18.4498 11.7422L17.6625 12.0271C17.5478 12.0687 17.4633 12.1672 17.4402 12.2864C16.8323 15.3502 14.1216 17.5734 10.9953 17.5734C10.8031 17.5734 10.6479 17.7285 10.6479 17.9207V21.5021C10.6479 21.6712 10.7695 21.8147 10.9351 21.8437C10.9548 21.8472 10.9756 21.8495 10.9953 21.8495C11.1389 21.8495 11.2709 21.7603 11.3218 21.6202L12.3118 18.8887C15.7311 18.3248 18.4302 15.5679 18.9118 12.1174C18.9292 11.997 18.8817 11.8765 18.7868 11.8001C18.7243 11.748 18.6467 11.7214 18.5679 11.7214ZM18.5679 12.4161H18.5737H18.5679Z"
        fill="#44C4E2"
      />
      <path
        d="M9.91504 18.7475C6.5062 18.2716 3.78862 15.5829 3.27452 12.1915L0.446941 11.1667C0.368204 11.1378 0.32073 11.0556 0.335783 10.9734C0.350835 10.89 0.422625 10.8298 0.507151 10.8298H4.08852C4.18462 10.8298 4.2622 10.9074 4.2622 11.0035C4.2622 14.2132 6.54441 16.9956 9.68926 17.6185C9.74831 17.6313 9.7981 17.6729 9.81894 17.7308L10.1038 18.5182C10.1246 18.5749 10.113 18.6386 10.076 18.6873C10.0424 18.7278 9.99262 18.7521 9.94052 18.7521C9.93126 18.7498 9.92315 18.7486 9.91504 18.7475Z"
        fill="#44C4E2"
      />
      <path
        d="M4.0885 11.0023C4.0885 14.3544 6.48303 17.16 9.65566 17.7887L9.94051 18.5761C6.57219 18.106 3.89861 15.4371 3.43314 12.063L0.505978 11.0023H4.0885ZM4.0885 10.655H0.505978C0.336926 10.655 0.193347 10.7765 0.164399 10.9421C0.135452 11.1088 0.229241 11.2721 0.387873 11.3288L3.11935 12.3188C3.68324 15.7381 6.44019 18.4372 9.89072 18.9188C9.90693 18.9212 9.92314 18.9223 9.93935 18.9223C10.0436 18.9223 10.1431 18.876 10.2091 18.7938C10.2856 18.6988 10.3076 18.5715 10.2659 18.4568L9.98103 17.6695C9.93935 17.5548 9.84093 17.4703 9.72166 17.4472C6.65787 16.8393 4.43472 14.1286 4.43472 11.0023C4.43587 10.8113 4.27956 10.655 4.0885 10.655Z"
        fill="#44C4E2"
      />
      <path
        d="M3.43773 10.105C3.39836 10.105 3.36015 10.0922 3.32773 10.0667C3.28026 10.0274 3.2571 9.96716 3.2652 9.90695C3.7411 6.49811 6.42973 3.78169 9.82236 3.26759L10.8471 0.440008C10.8726 0.369377 10.9386 0.324219 11.0104 0.324219C11.0196 0.324219 11.03 0.324219 11.0405 0.326535C11.1238 0.341587 11.184 0.413377 11.184 0.497903V4.07927C11.184 4.17538 11.1065 4.25296 11.0104 4.25296C7.80068 4.25296 5.01825 6.53517 4.39531 9.68001C4.38373 9.74022 4.34204 9.78885 4.28415 9.80969L3.49678 10.0945C3.4771 10.1015 3.45741 10.105 3.43773 10.105Z"
        fill="#44C4E2"
      />
      <path
        d="M11.0104 0.497896V4.07926C7.65829 4.07926 4.85271 6.47379 4.22397 9.64642L3.4366 9.93126C3.90671 6.56295 6.57565 3.88937 9.94976 3.4239L11.0104 0.497896ZM11.0104 0.150528C10.8668 0.150528 10.7348 0.239686 10.6839 0.379791L9.69386 3.11126C6.2746 3.67516 3.57555 6.43211 3.09386 9.88263C3.0765 10.0031 3.12397 10.1246 3.21892 10.2011C3.28144 10.252 3.35902 10.2786 3.43776 10.2786C3.47713 10.2786 3.51765 10.2717 3.55586 10.2578L4.34323 9.97295C4.45786 9.93242 4.54239 9.834 4.56555 9.71474C5.17344 6.65095 7.88407 4.42779 11.0104 4.42779C11.2026 4.42779 11.3578 4.27263 11.3578 4.08042V0.497896C11.3578 0.328844 11.2362 0.185265 11.0706 0.156317C11.0509 0.152844 11.0301 0.150528 11.0104 0.150528Z"
        fill="#44C4E2"
      />
      <path
        d="M17.9126 11.176C17.8165 11.176 17.7389 11.0984 17.7389 11.0023C17.7389 7.79264 15.4567 5.01022 12.3118 4.38611C12.2516 4.37453 12.203 4.33285 12.1833 4.27496L11.8985 3.48759C11.8765 3.42969 11.888 3.36601 11.9262 3.31853C11.9587 3.27685 12.0085 3.25369 12.0606 3.25369C12.0687 3.25369 12.0768 3.25369 12.0849 3.25485C15.4949 3.73075 18.2113 6.41938 18.7254 9.81201L21.5518 10.8367C21.6306 10.8657 21.678 10.9467 21.6641 11.0301C21.6502 11.1135 21.5785 11.1737 21.4939 11.1737H17.9126V11.176Z"
        fill="#44C4E2"
      />
      <path
        d="M12.0606 3.42969C15.4289 3.89979 18.1025 6.56874 18.568 9.94285L21.494 11.0035H17.9126C17.9126 7.65137 15.5181 4.84579 12.3454 4.21706L12.0606 3.42969ZM12.0606 3.08232C11.9564 3.08232 11.8568 3.12864 11.7908 3.21085C11.7144 3.30579 11.6924 3.43316 11.7341 3.54779L12.0189 4.33516C12.0606 4.44979 12.159 4.53432 12.2783 4.55748C15.3421 5.16537 17.5652 7.87601 17.5652 11.0023C17.5652 11.1945 17.7204 11.3497 17.9126 11.3497H21.494C21.663 11.3497 21.8066 11.2281 21.8355 11.0625C21.8645 10.897 21.7707 10.7337 21.6121 10.6758L18.8806 9.68579C18.3167 6.26653 15.5597 3.56748 12.1092 3.08579C12.093 3.08348 12.0768 3.08232 12.0606 3.08232Z"
        fill="#44C4E2"
      />
      <path
        d="M10.9641 21.6723C10.8807 21.6573 10.8205 21.5866 10.8205 21.5021V17.9208C10.8205 17.8246 10.8981 17.7471 10.9942 17.7471C14.2039 17.7471 16.9863 15.4649 17.6092 12.32C17.622 12.2598 17.6637 12.2112 17.7215 12.1915L18.5101 11.9066C18.5298 11.8985 18.5483 11.8951 18.5691 11.8951C18.6085 11.8951 18.6467 11.909 18.6791 11.9344C18.7266 11.9726 18.7509 12.0329 18.7417 12.0931C18.2658 15.5031 15.5771 18.2195 12.1845 18.7336L11.1574 21.56C11.132 21.6306 11.066 21.6758 10.9942 21.6758C10.9849 21.6758 10.9745 21.6746 10.9641 21.6723Z"
        fill="#44C4E2"
      />
      <path
        d="M18.5679 12.0687C18.0978 15.4371 15.4289 18.1106 12.0548 18.5761L10.9942 21.5021V17.9207C14.3463 17.9207 17.1518 15.5262 17.7806 12.3536L18.5679 12.0687ZM18.5679 11.7214C18.5286 11.7214 18.4881 11.7283 18.4498 11.7422L17.6625 12.0271C17.5478 12.0687 17.4633 12.1672 17.4402 12.2864C16.8323 15.3502 14.1216 17.5734 10.9953 17.5734C10.8031 17.5734 10.6479 17.7285 10.6479 17.9207V21.5021C10.6479 21.6712 10.7695 21.8147 10.9351 21.8437C10.9548 21.8472 10.9756 21.8495 10.9953 21.8495C11.1389 21.8495 11.2709 21.7603 11.3218 21.6202L12.3118 18.8887C15.7311 18.3248 18.4302 15.5679 18.9118 12.1174C18.9292 11.997 18.8817 11.8765 18.7868 11.8001C18.7243 11.748 18.6467 11.7214 18.5679 11.7214ZM18.5679 12.4161H18.5737H18.5679Z"
        fill="#44C4E2"
      />
      <path
        d="M9.91504 18.7475C6.5062 18.2716 3.78862 15.5829 3.27452 12.1915L0.446941 11.1667C0.368204 11.1378 0.32073 11.0556 0.335783 10.9734C0.350835 10.89 0.422625 10.8298 0.507151 10.8298H4.08852C4.18462 10.8298 4.2622 10.9074 4.2622 11.0035C4.2622 14.2132 6.54441 16.9956 9.68926 17.6185C9.74831 17.6313 9.7981 17.6729 9.81894 17.7308L10.1038 18.5182C10.1246 18.5749 10.113 18.6386 10.076 18.6873C10.0424 18.7278 9.99262 18.7521 9.94052 18.7521C9.93126 18.7498 9.92315 18.7486 9.91504 18.7475Z"
        fill="#44C4E2"
      />
      <path
        d="M4.0885 11.0023C4.0885 14.3544 6.48303 17.16 9.65566 17.7887L9.94051 18.5761C6.57219 18.106 3.89861 15.4371 3.43314 12.063L0.505978 11.0023H4.0885ZM4.0885 10.655H0.505978C0.336926 10.655 0.193347 10.7765 0.164399 10.9421C0.135452 11.1088 0.229241 11.2721 0.387873 11.3288L3.11935 12.3188C3.68324 15.7381 6.44019 18.4372 9.89072 18.9188C9.90693 18.9212 9.92314 18.9223 9.93935 18.9223C10.0436 18.9223 10.1431 18.876 10.2091 18.7938C10.2856 18.6988 10.3076 18.5715 10.2659 18.4568L9.98103 17.6695C9.93935 17.5548 9.84093 17.4703 9.72166 17.4472C6.65787 16.8393 4.43472 14.1286 4.43472 11.0023C4.43587 10.8113 4.27956 10.655 4.0885 10.655Z"
        fill="#44C4E2"
      />
    </Svg>
  )
}

export default Icon
