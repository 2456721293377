import React from 'react'
import Svg from '../../../components/Svg/Svg'
import { SvgProps } from '../../../components/Svg/types'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      marginRight="8px"
    >
      <g>
        <path fill="#44C4E2" d="M3.469.703v.703H5.39V5.07c0 3.973.02 4.274.257 5.102.684 2.39 2.602 4.168 5.055 4.695.684.149 1.914.149 2.586 0a6.344 6.344 0 001.524-.504 6.648 6.648 0 003.535-4.164c.242-.844.261-1.172.261-5.14V1.406h1.922V0H3.47zm0 0"/>
        <path fill="#44C4E2" d="M.008 5.469l.02 2.664.12.453c.254.93.715 1.7 1.403 2.355a4.745 4.745 0 002.36 1.25c.394.082 1.058.121 1.058.067a1.88 1.88 0 00-.121-.266 8.462 8.462 0 01-.301-.691l-.184-.442-.336-.082a3.538 3.538 0 01-2.25-1.847c-.34-.688-.34-.72-.363-2.828l-.012-1.883h2.582V2.812H-.004zm0 0M20.016 3.516v.703h2.582l-.012 1.883c-.023 2.109-.023 2.14-.363 2.828a3.533 3.533 0 01-2.258 1.847l-.332.082-.156.399a9.404 9.404 0 01-.301.687 2.924 2.924 0 00-.145.313c0 .055.664.015 1.055-.067a4.716 4.716 0 002.375-1.261 4.964 4.964 0 001.39-2.344l.122-.453.02-2.664.01-2.657h-3.987zm0 0M9.938 17.816v1.676l-.711.016c-.797.02-.985.07-1.32.367-.407.355-.5.71-.5 1.93v.789H5.39V24h13.218v-1.406h-2.007l-.016-.977c-.031-1.105-.066-1.273-.371-1.62a1.743 1.743 0 00-.457-.34c-.246-.126-.309-.134-.977-.153l-.719-.012v-3.347l-.523.113c-.469.101-.648.117-1.539.117-.89 0-1.07-.016-1.531-.117l-.531-.113zm0 0"/>
      </g>
    </Svg>
  )
}

export default Icon
