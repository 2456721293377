import React from 'react'
import Svg from '../../../components/Svg/Svg'
import { SvgProps } from '../../../components/Svg/types'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 20 20" {...props}>
      <g id="24 / basic / more-horizontal">
        <path
          id="icon"
          d="M4.45833 11.5833C3.58388 11.5833 2.875 10.8744 2.875 10C2.875 9.12555 3.58388 8.41666 4.45833 8.41666C5.33278 8.41666 6.04167 9.12555 6.04167 10C6.04167 10.8744 5.33278 11.5833 4.45833 11.5833ZM10 11.5833C9.12555 11.5833 8.41667 10.8744 8.41667 10C8.41667 9.12555 9.12555 8.41666 10 8.41666C10.8745 8.41666 11.5833 9.12555 11.5833 10C11.5833 10.8744 10.8745 11.5833 10 11.5833ZM13.9583 10C13.9583 10.8744 14.6672 11.5833 15.5417 11.5833C16.4161 11.5833 17.125 10.8744 17.125 10C17.125 9.12555 16.4161 8.41666 15.5417 8.41666C14.6672 8.41666 13.9583 9.12555 13.9583 10Z"
          fill="#44C4E2"
        />
      </g>{' '}
    </Svg>
  )
}

export default Icon
