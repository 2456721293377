import React from 'react'
import Svg from '../../../components/Svg/Svg'
import { SvgProps } from '../../../components/Svg/types'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg width="32" height="32" viewBox="0 0 32 32">
      <g>
        <g>
          <path d="M19.017 17.868a16.131 16.131 0 0 1-9.063 2.116 8.874 8.874 0 0 1-6.476-2.763 6.929 6.929 0 0 1-1.371-5.606 7.214 7.214 0 0 1 .659-2.123l.045-.092a14.274 14.274 0 0 1 4.957-5.387 14.528 14.528 0 0 1 14.219-.784 14.329 14.329 0 0 1 5.532 4.807 14.069 14.069 0 0 1 1.033 14.036 14.258 14.258 0 0 1-4.77 5.547 14.489 14.489 0 0 1-6.925 2.481l.152 1.728a16.258 16.258 0 0 0 7.774-2.787 16 16 0 0 0 5.357-6.227 15.788 15.788 0 0 0-1.163-15.753 16.08 16.08 0 0 0-6.214-5.393 16.3 16.3 0 0 0-15.962.9 16.016 16.016 0 0 0-5.555 6.051l-.061.124a8.9 8.9 0 0 0-.815 2.635 8.662 8.662 0 0 0 1.752 6.961 10.482 10.482 0 0 0 7.73 3.388 17.741 17.741 0 0 0 10.83-2.869Z"/>
          <path d="M22.373 19.834a18.979 18.979 0 0 1-11.463 4.194c-6.891.376-9.764-1.837-9.79-1.86l-.556.678.56-.666L0 23.512c.122.1 2.876 2.3 9.345 2.3.53 0 1.086 0 1.665-.045 7.44-.41 11.528-3.618 12.952-4.988Z"/>
          <path d="M25.36 21.625a12.79 12.79 0 0 1-3.429 3.087c-4.663 2.974-10.594 3.358-14.747 3.151L7.1 29.6c.7.034 1.368.049 2.019.049 11.71 0 16.442-5.271 17.764-7.153l-1.524-.885"/>
          <path d="M24.914 15.057a1.389 1.389 0 1 0-1.406-1.389 1.4 1.4 0 0 0 1.406 1.389Z"/ >
        </g>
      </g>
    </Svg>
  )
}

export default Icon
