import { Network } from '@hyperjump-defi/sdk'
import getNetwork from 'utils/getNetwork'
import tokens from './tokens'
import { PoolCategory, PoolConfig } from './types'

const { config } = getNetwork()
// note: the magic numbers below are calculated from allocation points

const pools: Record<Network, PoolConfig[]> = {
  [Network.METIS_TESTNET]: [],
  [Network.METIS]: [
    {
      sousId: 0,
      stakingToken: tokens.jump,
      earningToken: tokens.jump,
      contractAddress: {
        1088: tokens.xjump.address[1088],
      },
      poolCategory: PoolCategory.CORE,
      harvest: true,
      tokenPerBlock: (parseFloat(config.localEmissionRate) * 0.28846153846).toString(),
      sortOrder: 1,
      isFinished: false,
    },
  ],
  [Network.BSC]: [
    {
      sousId: 14,
      stakingToken: tokens.jump,
      earningToken: tokens.jump,
      contractAddress: {
        56: tokens.xjump.address[56], // xjump 2.1 contract
      },
      poolCategory: PoolCategory.CORE,
      harvest: true,
      tokenPerBlock: (parseFloat(config.localEmissionRate) * 0.17543859649).toString(),
      sortOrder: 1,
      isFinished: false,
    },
  ],
  [Network.FANTOM]: [
    {
      sousId: 12,
      stakingToken: tokens.jump,
      earningToken: tokens.jump,
      contractAddress: {
        250: tokens.xjump.address[250], // xjump 2.1 contract
      },
      poolCategory: PoolCategory.CORE,
      harvest: true,
      tokenPerBlock: (parseFloat(config.localEmissionRate) * 0.27027027027).toString(),
      sortOrder: 1,
      isFinished: false,
    },
  ],
  [Network.BSC_TESTNET]: [],
}

export default pools
