import { BNB, FANTOM, METIS, ChainId, CurrencyAmount, Network } from '@hyperjump-defi/sdk'
import tokens from 'config/constants/tokens'

const networks = [
  {
    name: 'BSC',
    network: Network.BSC,
    id: 56,
    url: process.env.REACT_APP_BSC_BASE_URL,
    networkTokenUsdFarmPid: 3,
    farmingTokenUsdFarmPid: 1,
    farmingTokenNetworkTokenFarmPid: 1,
    govTokenNetworkTokenFarmPid: 2,
    wrappedFarmingTokenPid: 14,
    coreFarms: [1, 2, 3],
    farmingToken: tokens.jump,
    govToken: tokens.hypr,
    networkToken: tokens.bnb,
    wrappedNetworkToken: tokens.wbnb,
    wrappedFarmingToken: tokens.xjump,
    showGovTokenStats: true,
    farmMultiplierScale: 100,
    baseCurrencyAmount: CurrencyAmount.bnb,
    baseCurrency: BNB,
    swapTokenListUrl: 'https://raw.githubusercontent.com/HyperJump-DeFi/swap-token-list/master/56.json',
    scannerName: 'BSC Scan',
    scannerUrl: `https://api.bscscan.com/api?module=account&action=txlist&address=`,
    approvalDomainName: 'Thugswap Street LP',
    votingLink: 'https://snapshot.org/#/bsc.hyperjumpdao.eth',
    bridgePath: 'https://www.binance.org/en/bridge?utm_source=Hyperjump',
    contractsLink: 'https://github.com/HyperJump-DeFi/HyperJump-Contracts',
    infoLink: 'https://bsc-info.hyperjump.app',
    buyLink: `/swap?inputCurrency=BNB&outputCurrency=${tokens.jump.address[56]}`,
    supportedChainIds: [ChainId.BSC_MAINNET],
    vaultCompoundTime: '8 hours',
    localEmissionRate: '1.055090528',
    swapTokenList: `../subModules/swap-token-list/56.json`,
  },
  {
    name: 'FTM',
    network: Network.FANTOM,
    id: 250,
    url: process.env.REACT_APP_FTM_BASE_URL,
    networkTokenUsdFarmPid: 3,
    farmingTokenUsdFarmPid: 1,
    farmingTokenNetworkTokenFarmPid: 1,
    govTokenNetworkTokenFarmPid: 2,
    wrappedFarmingTokenPid: 12,
    coreFarms: [1, 2, 3],
    farmingToken: tokens.jump,
    govToken: tokens.aurora,
    networkToken: tokens.ftm,
    wrappedNetworkToken: tokens.wftm,
    wrappedFarmingToken: tokens.xjump,
    showGovTokenStats: true,
    farmMultiplierScale: 100,
    baseCurrencyAmount: CurrencyAmount.ftm,
    baseCurrency: FANTOM,
    swapTokenListUrl: 'https://raw.githubusercontent.com/HyperJump-DeFi/swap-token-list/master/250.json',
    scannerName: 'FTM Scan',
    scannerUrl: `https://api.ftmscan.com/api?module=account&action=txlist&address=`,
    approvalDomainName: 'HyperSwap LP',
    votingLink: 'https://snapshot.org/#/ftm.hyperjumpdao.eth',
    bridgePath: '',
    contractsLink: 'https://github.com/HyperJump-DeFi/HyperJump-Contracts',
    infoLink: 'https://dexscreener.com/fantom/hyperjump/',
    buyLink: `/swap?inputCurrency=FTM&outputCurrency=${tokens.jump.address[250]}`,
    supportedChainIds: [ChainId.FTM_MAINNET],
    vaultCompoundTime: 'hour',
    localEmissionRate: '1.056993066',
    swapTokenList: `../subModules/swap-token-list/250.json`,
  },
  {
    name: 'METIS',
    network: Network.METIS,
    id: 1088,
    url: process.env.REACT_APP_METIS_BASE_URL,
    wrappedFarmingTokenPid: 0,
    farmingTokenNetworkTokenFarmPid: 1,
    farmingTokenUsdFarmPid: 2,
    networkTokenUsdFarmPid: 3,
    govTokenNetworkTokenFarmPid: 1,
    coreFarms: [0, 1, 2, 3],
    farmingToken: tokens.jump,
    govToken: tokens.govtokenplaceholder, // fake aurrora
    networkToken: tokens.metis,
    wrappedNetworkToken: tokens.metis,
    wrappedFarmingToken: tokens.xjump,
    showGovTokenStats: false,
    farmMultiplierScale: 100,
    baseCurrencyAmount: CurrencyAmount.metis,
    baseCurrency: METIS,
    scannerName: 'Andromeda explorer',
    scannerUrl: 'https://andromeda-explorer.metis.io/api?module=account&action=txlist&address=',
    approvalDomainName: 'HyperJump LP Token',
    votingLink: 'https://snapshot.org/#/metis.hyperjumpdao.eth',
    bridgePath: 'https://www.binance.org/en/bridge?utm_source=Hyperjump',
    contractsLink: 'https://github.com/HyperJump-DeFi/HyperJump-Contracts',
    buyLink: `/swap?inputCurrency=METIS&outputCurrency=${tokens.jump.address[1088]}`,
    infoLink: 'https://metis-info.hyperjump.app',
    supportedChainIds: [ChainId.METIS],
    vaultCompoundTime: '8 hours',
    localEmissionRate: '1.056993066',
    swapTokenList: `../subModules/swap-token-list/250.json`,
    swapTokenListUrl: 'https://raw.githubusercontent.com/HyperJump-DeFi/swap-token-list/master/1088.json',
  },
]

export default networks
