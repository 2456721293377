import React from 'react'
import Svg from '../../../components/Svg/Svg'
import { SvgProps } from '../../../components/Svg/types'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 20 20" {...props}>
      <path
        d="M9.5,0c-5,0-9,4-9,9s4,9,9,9s9-4,9-9S14.5,0,9.5,0z M16.3,6.3c-0.9-0.4-1.9-0.8-3.1-1c-0.2-1.2-0.6-2.2-1-3.1
	C14.1,2.9,15.6,4.4,16.3,6.3z M9.5,1.6c0.7,0,1.5,1.3,2,3.4c-0.6-0.1-1.3-0.1-2-0.1s-1.4,0-2,0.1C8,3,8.8,1.6,9.5,1.6z M2.1,9
	c0-0.7,1.3-1.5,3.4-2C5.4,7.6,5.4,8.3,5.4,9s0,1.4,0.1,2C3.5,10.5,2.1,9.7,2.1,9z M2.7,11.7c0.9,0.4,1.9,0.8,3.1,1
	c0.2,1.2,0.6,2.2,1,3.1C4.9,15.1,3.4,13.6,2.7,11.7z M5.8,5.2c-1.2,0.2-2.2,0.6-3.1,1c0.8-1.9,2.2-3.4,4.1-4.1C6.3,3,6,4.1,5.8,5.2z
	 M9.5,16.4c-0.7,0-1.5-1.3-2-3.4c0.6,0.1,1.3,0.1,2,0.1s1.4,0,2-0.1C11,15,10.2,16.4,9.5,16.4z M11.8,11.3c-0.7,0.1-1.5,0.1-2.3,0.1
	s-1.6,0-2.3-0.1C7.1,10.6,7,9.8,7,9s0-1.6,0.1-2.3c0.7-0.1,1.5-0.1,2.3-0.1s1.6,0,2.3,0.1C11.9,7.4,12,8.2,12,9S11.9,10.6,11.8,11.3
	z M12.2,15.8c0.4-0.9,0.8-1.9,1-3.1c1.2-0.2,2.2-0.6,3.1-1C15.6,13.6,14.1,15.1,12.2,15.8z M13.5,11c0.1-0.6,0.1-1.3,0.1-2
	s0-1.4-0.1-2c2,0.5,3.4,1.3,3.4,2S15.5,10.5,13.5,11z"
      />
    </Svg>
  )
}

export default Icon
