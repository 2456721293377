import React from 'react'
import Svg from '../../../components/Svg/Svg'
import { SvgProps } from '../../../components/Svg/types'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 20 20" {...props}>
      <path d="M16.4773 2.08333H3.52271C2.72775 2.08333 2.08331 2.79221 2.08331 3.66666V16.3333C2.08331 17.2078 2.72775 17.9167 3.52271 17.9167H4.45831V18.7083H6.04165V17.9167H13.9583V18.7083H15.5416V17.9167H16.4773C17.2722 17.9167 17.9166 17.2078 17.9166 16.3333V3.66666C17.9166 2.79221 17.2722 2.08333 16.4773 2.08333ZM3.66665 16.3333V3.66666H16.3333V5.24999H15.5416V6.83333H16.3333V13.1667H15.5416V14.75H16.3333V16.3333H3.66665ZM14.5181 13.3985L13.3985 14.5181L11.6086 12.7282C11.1372 13.0068 10.5872 13.1667 9.99998 13.1667C9.41271 13.1667 8.86279 13.0068 8.39134 12.7282L6.60144 14.5181L5.48185 13.3985L7.27175 11.6086C6.99317 11.1372 6.83331 10.5873 6.83331 10C6.83331 9.41273 6.99317 8.8628 7.27175 8.39136L5.48185 6.60145L6.60144 5.48187L8.39134 7.27177C8.86279 6.99319 9.41271 6.83333 9.99998 6.83333C10.5872 6.83333 11.1372 6.99319 11.6086 7.27177L13.3985 5.48187L14.5181 6.60145L12.7282 8.39135C13.0068 8.8628 13.1666 9.41273 13.1666 10C13.1666 10.5873 13.0068 11.1372 12.7282 11.6086L14.5181 13.3985ZM11.5833 9.99999C11.5833 10.8744 10.8744 11.5833 9.99998 11.5833C9.12553 11.5833 8.41665 10.8744 8.41665 9.99999C8.41665 9.12554 9.12553 8.41666 9.99998 8.41666C10.8744 8.41666 11.5833 9.12554 11.5833 9.99999Z" fill="#44C4E2"/>
    </Svg>
  )
}

export default Icon
