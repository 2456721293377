import { ChainId } from '@hyperjump-defi/sdk'
import contracts from 'config/constants/contracts'
import MULTICALL_ABI from './abi.json'

const SWAP_MULTICALL_NETWORKS: { [chainId in ChainId]: string } = {
  [ChainId.BSC_MAINNET]: contracts.multiCall[ChainId.BSC_MAINNET],
  [ChainId.BSC_TESTNET]: contracts.multiCall[ChainId.BSC_TESTNET],
  [ChainId.FTM_MAINNET]: contracts.multiCall[ChainId.FTM_MAINNET],
  [ChainId.FTM_TESTNET]: contracts.multiCall[ChainId.FTM_MAINNET], // does NOT exist
  [ChainId.METIS]: contracts.multiCall[ChainId.METIS],
  [ChainId.METIS_TESTNET]: contracts.multiCall[ChainId.METIS] // also does not exist
}

export { MULTICALL_ABI, SWAP_MULTICALL_NETWORKS }
