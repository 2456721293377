import React from 'react'
import Svg from '../../../components/Svg/Svg'
import { SvgProps } from '../../../components/Svg/types'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 20 20" {...props}>
      <path
        d="M7.871 7.55099C7.358 7.55099 6.953 8.00099 6.953 8.54999C6.953 9.09899 7.367 9.54899 7.871 9.54899C8.384 9.54899 8.789 9.09899 8.789 8.54999C8.798 8.00099 8.384 7.55099 7.871 7.55099ZM11.156 7.55099C10.643 7.55099 10.238 8.00099 10.238 8.54999C10.238 9.09899 10.652 9.54899 11.156 9.54899C11.669 9.54899 12.074 9.09899 12.074 8.54999C12.074 8.00099 11.669 7.55099 11.156 7.55099Z"
        fill="#44C4E2"
      />
      <path
        d="M15.53 0H3.47C2.453 0 1.625 0.828 1.625 1.854V14.022C1.625 15.048 2.453 15.876 3.47 15.876H13.676L13.199 14.211L14.351 15.282L15.44 16.29L17.375 18V1.854C17.375 0.828 16.547 0 15.53 0ZM12.056 11.754C12.056 11.754 11.732 11.367 11.462 11.025C12.641 10.692 13.091 9.954 13.091 9.954C12.722 10.197 12.371 10.368 12.056 10.485C11.606 10.674 11.174 10.8 10.751 10.872C9.887 11.034 9.095 10.989 8.42 10.863C7.907 10.764 7.466 10.62 7.097 10.476C6.89 10.395 6.665 10.296 6.44 10.17C6.413 10.152 6.386 10.143 6.359 10.125C6.341 10.116 6.332 10.107 6.323 10.098C6.161 10.008 6.071 9.945 6.071 9.945C6.071 9.945 6.503 10.665 7.646 11.007C7.376 11.349 7.043 11.754 7.043 11.754C5.054 11.691 4.298 10.386 4.298 10.386C4.298 7.488 5.594 5.139 5.594 5.139C6.89 4.167 8.123 4.194 8.123 4.194L8.213 4.302C6.593 4.77 5.846 5.481 5.846 5.481C5.846 5.481 6.044 5.373 6.377 5.22C7.34 4.797 8.105 4.68 8.42 4.653C8.474 4.644 8.519 4.635 8.573 4.635C9.122 4.563 9.743 4.545 10.391 4.617C11.246 4.716 12.164 4.968 13.1 5.481C13.1 5.481 12.389 4.806 10.859 4.338L10.985 4.194C10.985 4.194 12.218 4.167 13.514 5.139C13.514 5.139 14.81 7.488 14.81 10.386C14.81 10.386 14.045 11.691 12.056 11.754Z"
        fill="#44C4E2"
      />
    </Svg>
  )
}

export default Icon
