import React from 'react'
import Svg from '../../../components/Svg/Svg'
import { SvgProps } from '../../../components/Svg/types'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 20 20" {...props}>
      <path
        id="icon"
        d="M9.99998 3.66668C11.8949 3.66668 13.5201 4.52064 14.5971 6.04168H11.5833V7.62501H17.125V2.08334H15.5416V4.67237C14.1759 3.0195 12.22 2.08334 9.99998 2.08334C5.62773 2.08334 2.08331 5.62776 2.08331 10H3.66665C3.66665 6.50221 6.50218 3.66668 9.99998 3.66668ZM9.99998 16.3333C8.10503 16.3333 6.47987 15.4794 5.40282 13.9583H8.41665V12.375H2.87498V17.9167H4.45831V15.3277C5.82402 16.9805 7.77996 17.9167 9.99998 17.9167C14.3722 17.9167 17.9166 14.3723 17.9166 10H16.3333C16.3333 13.4978 13.4978 16.3333 9.99998 16.3333Z"
        fill="#44C4E2"
      />
    </Svg>
  )
}

export default Icon
