import React from 'react'
import Svg from '../../../components/Svg/Svg'
import { SvgProps } from '../../../components/Svg/types'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 20 20" {...props}>
      <path
        d="M9.83337 0C4.86237 0 0.833374 4.029 0.833374 9C0.833374 13.971 4.86237 18 9.83337 18C14.8044 18 18.8334 13.971 18.8334 9C18.8334 4.029 14.8044 0 9.83337 0ZM14.0384 6.844C14.0424 6.937 14.0444 7.031 14.0444 7.124C14.0444 9.991 11.8614 13.297 7.87137 13.297C6.64537 13.297 5.50537 12.938 4.54537 12.323C4.71537 12.343 4.88737 12.353 5.06237 12.353C6.07937 12.353 7.01437 12.006 7.75737 11.425C6.80837 11.407 6.00637 10.78 5.73037 9.918C5.86337 9.942 5.99937 9.956 6.13937 9.956C6.33737 9.956 6.52937 9.929 6.71137 9.879C5.71837 9.681 4.97037 8.804 4.97037 7.753C4.97037 7.743 4.97037 7.734 4.97037 7.725C5.26337 7.888 5.59737 7.986 5.95337 7.997C5.37137 7.608 4.98837 6.943 4.98837 6.191C4.98837 5.794 5.09537 5.421 5.28237 5.101C6.35237 6.414 7.95137 7.277 9.75437 7.368C9.71637 7.208 9.69737 7.043 9.69737 6.872C9.69737 5.674 10.6694 4.703 11.8674 4.703C12.4914 4.703 13.0554 4.967 13.4514 5.388C13.9454 5.291 14.4094 5.11 14.8284 4.861C14.6664 5.367 14.3224 5.793 13.8744 6.061C14.3134 6.008 14.7314 5.892 15.1204 5.719C14.8304 6.155 14.4624 6.538 14.0384 6.844Z"
        fill="#44C4E2"
      />
    </Svg>
  )
}

export default Icon
