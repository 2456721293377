import React from 'react'
import Svg from '../../../components/Svg/Svg'
import { SvgProps } from '../../../components/Svg/types'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 20 20" {...props}>
      <path
        d="M14.5451 2.095C14.1351 1.845 11.4851 1.465 11.4851 1.465L9.54514 0.845001C8.63514 1.095 4.60514 1.785 4.29514 2.125C3.98514 2.465 0.575135 4.155 0.545135 4.535C0.515135 4.905 2.95514 6.465 3.13514 6.935C3.32514 7.405 3.90514 11.395 4.16514 11.965C4.26514 12.175 5.01513 12.655 5.01513 12.655L5.92514 13.435L6.62513 13.875L8.99514 19.155L11.2251 17.945C11.2251 17.945 11.2051 14.845 11.2351 14.315C11.2651 13.785 11.2651 13.315 11.2651 13.315L12.6651 11.715L13.1351 8.285C13.1351 8.285 13.6051 9.345 13.7051 9.405C13.7951 9.465 14.9551 8.595 14.9551 8.595C14.9551 8.595 15.9851 5.535 16.3851 5.095C16.7951 4.655 17.4551 4.035 17.4551 4.035C17.4551 4.035 14.9551 2.345 14.5451 2.095ZM9.79514 18.025L9.66514 12.775L10.6251 13.225L10.7351 17.355L9.79514 18.025ZM12.3151 11.105L10.9851 12.065L12.7151 7.275L12.3151 11.105ZM12.1651 6.615L9.98514 11.875H9.54514L10.9851 5.815L9.54514 4.495L12.8251 4.415L12.1651 6.615ZM14.6451 7.705L13.9851 8.275L13.4851 5.335L15.5051 4.645L14.6451 7.705Z"
        fill="#44C4E2"
      />
    </Svg>
  )
}

export default Icon
