import React from 'react'
import Svg from '../../../components/Svg/Svg'
import { SvgProps } from '../../../components/Svg/types'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 20 20" {...props}>
      <path d="M17.376 10.079H8.06903L11.548 7.95499C11.548 7.95499 14.584 8.67999 17.376 10.079Z" fill="#44C4E2" />
      <path
        d="M14.722 10.079C14.722 10.079 8.58102 11.582 7.07702 15.841C6.60602 17.172 7.11302 19.5 7.11302 19.5L4.48402 19.492C4.48402 19.492 3.98102 12.833 3.29902 11.974C2.41402 10.86 1.45002 10.638 0.624023 10.079C2.86502 10.079 14.722 10.079 14.722 10.079Z"
        fill="#44C4E2"
      />
      <path
        d="M14.722 10.079C14.722 10.079 8.58102 8.481 7.07702 4.223C6.60702 2.891 7.11302 0.516 7.11302 0.516L4.48302 0.5C4.48302 0.5 3.98002 7.242 3.29802 8.101C2.41402 9.215 1.45002 9.379 0.624023 10.079C2.86502 10.079 14.722 10.079 14.722 10.079Z"
        fill="#44C4E2"
      />
      <path d="M17.376 10.079H8.06903L11.548 12.203C11.548 12.203 14.584 11.338 17.376 10.079Z" fill="#44C4E2" />
      <path
        d="M11.548 12.203C11.548 12.203 9.29202 12.224 7.44902 11.824C4.87802 11.265 2.92002 10.532 0.624023 10.079C3.35402 10.079 9.16802 10.079 9.16802 10.079L11.548 12.203Z"
        fill="#44C4E2"
      />
      <path
        d="M11.548 7.95499C11.548 7.95499 9.29202 7.94399 7.44902 8.34399C4.87802 8.90299 2.92002 9.62599 0.624023 10.079C3.35402 10.079 9.30402 10.079 9.30402 10.079L11.548 7.95499Z"
        fill="#44C4E2"
      />
      <path
        d="M7.12105 1.5H4.41405C4.29405 1.5 4.16005 1.414 4.11105 1.303L3.88705 0.786C3.82105 0.633 3.88805 0.5 4.04005 0.5H7.49505C7.64705 0.5 7.71405 0.633 7.64805 0.786L7.42305 1.303C7.37505 1.414 7.24005 1.5 7.12105 1.5Z"
        fill="#44C4E2"
      />
      <path
        d="M7.12101 18.5H4.41401C4.29501 18.5 4.16001 18.586 4.11201 18.697L3.88801 19.214C3.82101 19.367 3.88801 19.5 4.04001 19.5H7.49501C7.64701 19.5 7.71401 19.367 7.64801 19.214L7.42401 18.697C7.37501 18.586 7.24001 18.5 7.12101 18.5Z"
        fill="#44C4E2"
      />
      <path
        d="M4.28604 10.079H1.44704C1.32104 10.079 1.22904 9.961 1.26104 9.838C1.34404 9.517 1.50404 8.942 1.67804 8.55C1.70904 8.481 1.77804 8.44 1.85304 8.44H4.28504C4.39104 8.44 4.47804 8.526 4.47804 8.633V9.887C4.47804 9.993 4.39204 10.079 4.28604 10.079Z"
        fill="#44C4E2"
      />
      <path
        d="M4.28604 10.098H1.44704C1.32104 10.098 1.22904 10.216 1.26104 10.339C1.34404 10.661 1.50504 11.236 1.67904 11.628C1.71004 11.697 1.77904 11.738 1.85404 11.738H4.28604C4.39204 11.738 4.47904 11.652 4.47904 11.545V10.29C4.47804 10.184 4.39204 10.098 4.28604 10.098Z"
        fill="#44C4E2"
      />
    </Svg>
  )
}

export default Icon
