import React from 'react'
import Svg from '../../../components/Svg/Svg'
import { SvgProps } from '../../../components/Svg/types'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg" marginRight="8px">
      <path
        d="M14.0533 13.9592C14.5955 13.9592 15.0351 13.5004 15.0351 12.9345C15.0351 12.3686 14.5955 11.9099 14.0533 11.9099C13.5111 11.9099 13.0715 12.3686 13.0715 12.9345C13.0715 13.5004 13.5111 13.9592 14.0533 13.9592Z"
        fill="#44C4E2"
      />
      <path
        d="M3.24564 19H15.9759C17.7695 19 19.2215 17.548 19.2215 15.7526V15.4111C19.2215 15.1553 19.0087 14.9837 18.7941 14.9837H13.2417C12.5586 14.9837 12.0454 14.4276 12.0454 13.7874V12.0779C12.0454 11.3948 12.6015 10.8816 13.2417 10.8816H18.7941C19.0499 10.8816 19.2215 10.6688 19.2215 10.4542V7.29614C19.2215 7.0404 19.0087 6.86876 18.7941 6.86876H1.62368V6.09983C1.62368 5.50254 2.09396 5.03226 2.69125 5.03226H4.92767L7.14864 3.62313H2.69125C1.1963 3.62141 0 4.60488 0 6.09983V7.33904V13.9608V15.7544C0 17.548 1.45204 19 3.24564 19Z"
        fill="#44C4E2"
      />
      <path
        d="M14.4689 0.145669L7.79401 4.62537C7.30485 4.95319 7.53827 5.71526 8.12527 5.71526H16.968C17.6082 5.71526 18.0218 5.04073 17.7334 4.46918L15.7133 0.470061C15.4799 0.0100762 14.8963 -0.140964 14.4689 0.145669Z"
        fill="#44C4E2"
      />
    </Svg>
  )
}

export default Icon
