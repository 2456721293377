import { Network } from '@hyperjump-defi/sdk'

export default {
  [Network.METIS]: {
    Netswap: {
      name: 'Netswap',
      logoUrl: 'https://netswap.io/logo.svg',
      url: 'https://raw.githubusercontent.com/beefyfinance/beefy-api/master/src/data/metis/netswapLpPools.json',
      imageUrl: 'https://github.com/HyperJump-DeFi/evm-token-icons/blob/main/1088/',
    },
    /*  Tethys: {
      name: 'Tethys',
      logoUrl: 'https://avatars.githubusercontent.com/u/96704061?s=200&v=4',
      url: 'https://github.com/beefyfinance/beefy-api/blob/master/src/data/metis/tethysLpPools.json',
      imageUrl: '',
    }, */
  },
  [Network.BSC]: {
    PancakeSwap: {
      name: 'PancakeSwap',
      logoUrl: 'https://pancakeswap.finance/images/tokens//0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82.png',
      url: 'https://raw.githubusercontent.com/beefyfinance/beefy-api/master/src/data/cakeLpPools.json',
      imageUrl: 'https://pancakeswap.finance/images/tokens',
    },
  },
  [Network.FTM]: {
    SpiritSwap: {
      name: 'SpiritSwap',
      logoUrl: 'https://swap.spiritswap.finance/images/coins/SPIRIT.png',
      url: 'https://raw.githubusercontent.com/beefyfinance/beefy-api/master/src/data/fantom/spiritPools.json',
      imageUrl: 'https://swap.spiritswap.finance/images/coins',
    },
    SpookySwap: {
      name: 'SpookySwap',
      logoUrl: 'https://assets.spookyswap.finance/tokens/BOO.png',
      url: 'https://raw.githubusercontent.com/beefyfinance/beefy-api/master/src/data/fantom/spookyLpPools.json',
      imageUrl: 'https://assets.spookyswap.finance/tokens',
    },
  },
}
